import { computed, reactive, toRefs } from 'vue';
import { defineStore } from 'pinia';

import { BOOKING_PANES, PINIA_STORE } from '~/constants';
import { useSnackbarStore } from '~/store';

import {
  formatBookingRow,
  useCargoBookingBaseStore,
} from '~/features/cargoBooking';
import {
  addBooking,
  getBookingCreatePayload,
  getBookingUpdatePayload,
  storeBookings,
  updateBooking,
  updateBookings,
  updatePeriodBookings,
} from '~/features/cargoBooking/create';

import type {
  CargoBookingPort,
  ICargoBooking,
  ICargoBookingRow,
  ICargoCreateBooking,
  ICargoPeriod,
  ICargoUpdateResponse,
  IOption,
} from '~/types';

interface IState {
  periodsData: ICargoPeriod<ICargoBookingRow>[];
  bookingsData: ICargoBookingRow[];
  tradelanesFilterOptions: IOption[];
  reviewTradeQuery: IOption[];
  isModalVisible: boolean;
  isPeriodStructure: boolean;
}

export const useCargoBookingCreateStore = defineStore(
  PINIA_STORE.CARGO_BOOKING_CREATE,
  () => {
    const baseStore = useCargoBookingBaseStore();

    const state = reactive<IState>({
      periodsData: [],
      bookingsData: [],
      tradelanesFilterOptions: [],
      reviewTradeQuery: [],
      isModalVisible: false,
      isPeriodStructure: true,
    });

    /* Getters */
    const _periodBookingsByTrade = computed(() => {
      if (!state.periodsData.length) return [];

      const bookings = state.periodsData.flatMap((period) => period.bookings);

      return bookings;
    });

    const periodsDataByTrade = computed(
      (): ICargoPeriod<ICargoBookingRow>[] => {
        if (!state.reviewTradeQuery.length) {
          return state.periodsData;
        }

        return state.periodsData.map((period) => ({
          ...period,
          bookings: _filterBookingsByTrade(period.bookings),
        }));
      },
    );

    const _periodBySelectedBooking = computed(() =>
      baseStore.state.selectedBooking?.id
        ? _getPeriodByBookingId(baseStore.state.selectedBooking.id)
        : null,
    );

    const totalErrors = computed(() => {
      if (state.isPeriodStructure)
        return baseStore.calculateValidationErrors(
          _periodBookingsByTrade.value,
        );
      return baseStore.calculateValidationErrors(state.bookingsData);
    });
    /* Getters */

    const setPeriodStructure = (value: boolean) => {
      state.isPeriodStructure = value;
    };

    const openModal = () => {
      state.isModalVisible = true;
    };

    const closeModal = () => {
      state.isModalVisible = false;
    };

    const actionOrModal = async (
      action?: () => void | Promise<void>,
      pane?: string | null,
    ) => {
      if (pane === BOOKING_PANES.DECLARATION_CREATE) {
        openModal();
        return;
      }

      await action?.();
    };

    const getBookingsMaxPorts = (bookings: ICargoBooking[]) => {
      const maxPorts = Math.max(
        ...bookings.map((booking) => booking.dischargePorts?.length),
      );

      return maxPorts;
    };

    const getPeriodBookingMaxPorts = (
      periods: ICargoPeriod<ICargoBooking>[],
    ) => {
      const bookings = periods.flatMap((period) => period.bookings);
      const maxPorts = getBookingsMaxPorts(bookings);

      return maxPorts;
    };

    const formatPeriodBookingsStates = (
      periods: ICargoPeriod<ICargoBooking>[],
      totalDischargePorts: number,
    ) => {
      const newPeriodsData = periods?.map((period) => ({
        ...period,
        bookings: formatBookingRow(
          period.bookings,
          totalDischargePorts,
          period.type,
        ),
      })) as ICargoPeriod<ICargoBookingRow>[];

      return newPeriodsData;
    };

    const setPeriodsData = (periods: ICargoPeriod<ICargoBooking>[]) => {
      if (!periods.length) {
        state.periodsData = [];
        return;
      }

      const maxPorts = getPeriodBookingMaxPorts(periods);
      baseStore.setTotalDischargePorts(maxPorts);
      state.periodsData = formatPeriodBookingsStates(periods, maxPorts);
    };

    const setBookingsData = (bookings: ICargoBooking[]) => {
      if (!bookings.length) {
        state.bookingsData = [];
        return;
      }

      const maxPorts = getBookingsMaxPorts(bookings);
      baseStore.setTotalDischargePorts(maxPorts);
      state.bookingsData = formatBookingRow(bookings, maxPorts);
    };

    const setTradelanesFilterOptions = (trades: IOption[]) => {
      state.tradelanesFilterOptions = trades;
    };

    const setReviewTradeQuery = (query: IOption[]) => {
      state.reviewTradeQuery = query;
    };

    /* Removal */
    const _removeSelectedBooking = (
      bookings: ICargoBookingRow[],
      selectedBookingId: string,
    ): ICargoBookingRow[] => {
      return bookings.filter(
        (booking) =>
          !(
            booking.id === selectedBookingId ||
            booking.continuationOfId === selectedBookingId
          ),
      );
    };

    const removePeriodBooking = () => {
      if (
        !_periodBookingsAndSelectedBookingExists() ||
        !baseStore.state.selectedBooking?.id
      ) {
        return false;
      }

      const filteredBookings = _removeSelectedBooking(
        _periodBySelectedBooking.value!.bookings,
        baseStore.state.selectedBooking!.id,
      );

      _setPeriodBookingsBySelectedBooking(filteredBookings);

      return true;
    };

    const removeBooking = () => {
      if (!baseStore.state.selectedBooking?.id) {
        return false;
      }

      const filteredBookings = _removeSelectedBooking(
        state.bookingsData,
        baseStore.state.selectedBooking?.id,
      );

      state.bookingsData = filteredBookings;

      return true;
    };
    /* Removal */

    /* Cancellation */
    const _cancelBookingsById = (
      bookings: ICargoBookingRow[],
      selectedBookingId: string,
    ): ICargoBookingRow[] => {
      return bookings.map((booking) => {
        if (
          booking.id === selectedBookingId ||
          booking.continuationOfId === selectedBookingId
        ) {
          return { ...booking, cancelled: true, errors: [] };
        }

        return booking;
      });
    };

    const cancelPeriodBookings = (): boolean => {
      const selected = baseStore.state.selectedBooking;

      if (
        !_periodBookingsAndSelectedBookingExists() ||
        !selected?.id ||
        !_periodBySelectedBooking.value
      ) {
        return false;
      }

      const updatedBookings = _cancelBookingsById(
        _periodBySelectedBooking.value.bookings,
        selected.id,
      );

      _setPeriodBookingsBySelectedBooking(updatedBookings);

      return true;
    };

    const cancelBookings = (): boolean => {
      const selected = baseStore.state.selectedBooking;

      if (!selected?.id) {
        return false;
      }

      const updatedBookings = _cancelBookingsById(
        state.bookingsData,
        selected.id,
      );

      state.bookingsData = updatedBookings;

      return true;
    };
    /* Cancellation */

    /* Update */
    const _updateSelectedBooking = (bookings: ICargoBookingRow[]) => {
      const selectedBookingId = baseStore.state.selectedBooking?.id;

      if (selectedBookingId) {
        const updatedSelectedBooking = bookings.find(
          (booking) => selectedBookingId === booking.id,
        );

        baseStore.selectBooking(updatedSelectedBooking);
      }
    };

    const updateBookingsRows = (
      orgId: OrganisationId,
      booking: ICargoBookingRow,
    ) => {
      const updatedBooking = getBookingUpdatePayload({
        booking,
        importId: baseStore.state.importId,
      });

      baseStore.setIsUpdating(true);

      updateBooking(orgId, updatedBooking)
        ?.then(({ data }: ICargoUpdateResponse) => {
          if (state.isPeriodStructure) {
            _updatePeriodBookingsData(data as ICargoPeriod<ICargoBooking>[]);
            return;
          }

          _updateBookingsData(data as ICargoBooking[]);
        })
        .finally(() => {
          baseStore.setIsUpdating(false);
        });
    };

    const _updatePeriodBookingsData = (data: ICargoPeriod<ICargoBooking>[]) => {
      const { updateBookings } = updatePeriodBookings(state.periodsData);

      updateBookings(
        formatPeriodBookingsStates(data, baseStore.state.totalDischargePorts),
      );

      _updateSelectedBooking(_periodBookingsByTrade.value);
    };

    const _updateBookingsData = (data: ICargoBooking[]) => {
      const newBookings = formatBookingRow(
        data,
        baseStore.state.totalDischargePorts,
      );

      updateBookings(state.bookingsData, newBookings);

      _updateSelectedBooking(state.bookingsData);
    };
    /* Update */

    const _clearHighlightedBookings = (bookings: ICargoBooking[]) => {
      return bookings.map((booking) => ({
        ...booking,
        highlight: false,
      }));
    };

    const _updateCreatedPeriodsData = (
      oldPeriodsData: ICargoPeriod<ICargoBooking>[],
      newPeriodsData: ICargoPeriod<ICargoBooking>[],
    ) => {
      const newBookings = newPeriodsData.map((item) => {
        const dataItem = oldPeriodsData.find(
          (cargo) => cargo.type === item.type,
        );

        const highlightedBookings = _updateCreatedBookings(
          item.bookings,
          dataItem.bookings,
        );

        return {
          ...dataItem,
          bookings: highlightedBookings,
        };
      });

      return newBookings;
    };

    const _updateCreatedBookings = (
      oldBookingsData: ICargoBooking[],
      newBookingsData: ICargoBooking[],
    ) => {
      const highlightedBookings = [
        ...oldBookingsData,
        ...newBookingsData.map((booking) => ({ ...booking, highlight: true })),
      ];

      highlightedBookings.sort(
        (a, b) => new Date(a.laycanStart) - new Date(b.laycanStart),
      );

      return highlightedBookings;
    };

    /* Creation */
    const addCreatedPeriodBooking = (
      periods: ICargoPeriod<ICargoBooking>[],
    ) => {
      const newMaxPorts = getPeriodBookingMaxPorts(periods);
      const maxPorts = Math.max(
        baseStore.state.totalDischargePorts,
        newMaxPorts,
      );

      baseStore.setTotalDischargePorts(maxPorts);

      const newPeriodsData = formatPeriodBookingsStates(periods, maxPorts);

      state.periodsData = _updateCreatedPeriodsData(
        state.periodsData,
        newPeriodsData,
      );

      setTimeout(() => {
        state.periodsData = state.periodsData.map((cargo) => ({
          ...cargo,
          bookings: _clearHighlightedBookings(cargo.bookings),
        }));
      }, 1500);

      const newBookings = periods.flatMap((item) => item.bookings);

      addCreatedBookingsSnackbar(newBookings);
    };

    const addCreatedBooking = (bookings: ICargoBooking[]) => {
      const newMaxPorts = getBookingsMaxPorts(bookings);
      const maxPorts = Math.max(
        baseStore.state.totalDischargePorts,
        newMaxPorts,
      );

      baseStore.setTotalDischargePorts(maxPorts);

      const newBookingsData = formatBookingRow(bookings, maxPorts);

      state.bookingsData = _updateCreatedBookings(
        state.bookingsData,
        newBookingsData,
      );

      setTimeout(() => {
        state.bookingsData = _clearHighlightedBookings(state.bookingsData);
      }, 1500);

      addCreatedBookingsSnackbar(bookings);
    };

    const createBooking = (
      orgId: OrganisationId,
      bookingData: ICargoCreateBooking,
    ) => {
      const { bookings, ...restBookingData } = bookingData;

      const formattedBookings = bookings.map((booking) =>
        getBookingCreatePayload({ booking }),
      );

      const formattedBookingData = {
        ...restBookingData,
        bookings: formattedBookings,
      };

      return addBooking(orgId, formattedBookingData);
    };

    const addCreatedBookingsSnackbar = (bookings: ICargoBooking[]) => {
      useSnackbarStore().add({
        type: 'success',
        text: `New Booking${
          bookings.length > 1 ? 'Bookings' : 'Booking'
        } created`,
      });
    };
    /* Creation */

    /* Submit */
    const submitDeclaration = (orgId: OrganisationId, isSyncVeson: boolean) => {
      if (baseStore.state.importId === null) {
        throw new Error('importId cannot be null');
      }

      return storeBookings(orgId, baseStore.state.importId, isSyncVeson);
    };
    /* Submit */

    /* Utilities */

    const _filterBookingsByTrade = (bookings: ICargoBookingRow[]) =>
      bookings.filter(({ trade }) =>
        state.reviewTradeQuery.find(({ id }) => id === trade?.id),
      );

    const fillPeriodBookingsDischargePorts = () => {
      if (!state.periodsData.length) return;

      baseStore.incrementTotalDischargePorts();

      const newPort: CargoBookingPort = {
        id: null,
        cargoBookingPortId: null,
        name: null,
        qty: null,
        edited: false,
      };

      state.periodsData = state.periodsData?.map((period) => ({
        ...period,
        bookings: period.bookings.map((booking) => ({
          ...booking,
          dischargePorts: [...booking.dischargePorts, newPort],
        })),
      }));
    };

    const fillBookingsDischargePorts = () => {
      if (!state.bookingsData.length) return;

      baseStore.incrementTotalDischargePorts();

      const newPort: CargoBookingPort = {
        id: null,
        cargoBookingPortId: null,
        name: null,
        qty: null,
        edited: false,
      };

      state.bookingsData = state.bookingsData?.map((booking) => ({
        ...booking,
        dischargePorts: [...booking.dischargePorts, newPort],
      }));
    };

    const _getPeriodByBookingId = (id: string) =>
      state.periodsData.find((cargo) =>
        cargo.bookings.find((cargoBooking) => cargoBooking.id === id),
      ) ?? null;

    const updatePeriodBookingNo = (id: string, newBookingNo: string) => {
      const booking = _periodBookingsByTrade.value.find(
        (booking) => booking.id === id,
      );

      if (booking) {
        booking.bookingNo = newBookingNo;
      }
    };

    const updateBookingNo = (id: string, newBookingNo: string) => {
      state.bookingsData = state.bookingsData.map((booking) => {
        if (booking.id === id) {
          return { ...booking, bookingNo: newBookingNo };
        }
        return booking;
      });
    };

    const _periodBookingsAndSelectedBookingExists = () =>
      !!_periodBySelectedBooking.value?.bookings ||
      !!baseStore.state.selectedBooking;

    const _setPeriodBookingsBySelectedBooking = (
      bookings: ICargoBookingRow[],
    ) => {
      const cargoTypeIndex = state.periodsData.findIndex(
        (cargoType) => cargoType.type === _periodBySelectedBooking.value?.type,
      );

      state.periodsData[cargoTypeIndex].bookings = bookings;
    };

    const resetStore = () => {
      state.periodsData = [];
      state.bookingsData = [];
      state.isModalVisible = false;
      state.tradelanesFilterOptions = [];
      state.reviewTradeQuery = [];
      state.isPeriodStructure = false;
      baseStore.reset();
    };
    /* Utilities */

    return {
      ...toRefs(state),
      ...toRefs(baseStore.state),

      selectBooking: baseStore.selectBooking,
      isRowSelected: baseStore.isRowSelected,
      setSettings: baseStore.setSettings,
      setImportId: baseStore.setImportId,
      unselectBooking: baseStore.unselectBooking,
      setTotalDischargePorts: baseStore.setTotalDischargePorts,
      setBookingTransferError: baseStore.setBookingTransferError,

      totalErrors,
      periodsDataByTrade,

      closeModal,
      actionOrModal,

      formatPeriodBookingsStates,
      setPeriodsData,
      setBookingsData,
      setPeriodStructure,
      setTradelanesFilterOptions,
      setReviewTradeQuery,

      fillPeriodBookingsDischargePorts,
      fillBookingsDischargePorts,

      addCreatedPeriodBooking,
      addCreatedBooking,
      createBooking,
      removePeriodBooking,
      removeBooking,
      updateBookingsRows,
      cancelPeriodBookings,
      cancelBookings,

      resetStore,

      submitDeclaration,
      updatePeriodBookingNo,
      updateBookingNo,
    };
  },
);
