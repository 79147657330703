<script setup lang="ts" name="ModalConfirmNeutral">
import { BUTTON_VARIANT } from '~/constants';

import ModalConfirm from '~/components/ModalConfirm.vue';

import type {
  IButtonVariant,
  IconType,
  ModalTextAlignType,
  ModalTextColorType,
  PromiseResolvePayload,
} from '~/types';

const {
  title,
  closeModal,
  confirm = 'Confirm',
  cancel = 'Cancel',
  cancelType = BUTTON_VARIANT.TERTIARY,
  confirmType = BUTTON_VARIANT.PRIMARY,
  message = '',
  icon = 'ic:baseline-delete',
} = defineProps<{
  title: string;
  confirm?: string;
  cancel?: string;
  cancelType?: IButtonVariant;
  confirmType?: IButtonVariant;
  message?: string;
  icon?: IconType;
  textColor?: ModalTextColorType;
  textAlign?: ModalTextAlignType;
  closeModal: (
    data: PromiseResolvePayload<'CLOSE'> | PromiseResolvePayload<'CONFIRM'>,
  ) => void;
}>();

const onClose = () => {
  closeModal({ action: 'CLOSE' });
};

const onConfirm = () => {
  closeModal({ action: 'CONFIRM' });
};
</script>

<template>
  <ModalConfirm
    modal-type="neutral"
    :title="title"
    :confirm="confirm"
    :cancel="cancel"
    :message="message"
    :icon="icon"
    :textColor="textColor"
    :textAlign="textAlign"
    :confirm-type="confirmType"
    :cancel-type="cancelType"
    @cancel="onClose"
    @confirm="onConfirm"
  />
</template>
