<script setup lang="ts" name="FieldBaseInput">
import { computed, onMounted, useTemplateRef } from 'vue';
import { useVModel } from '@vueuse/core';

import { useTabFocusEvent } from '~/features/useTabFocusEvent';

const props = withDefaults(
  defineProps<{
    modelValue: string | null | number;
    name?: string;
    label: string;
    type?: string;
    readonly?: boolean;
    disabled?: boolean;
    hasErrors?: boolean;
    optional?: boolean;
    capitalized?: boolean;
    autofocus?: boolean;
    autocomplete?: string;
    trim?: boolean;
    placeholder?: string;
    floatingLabel?: boolean;
  }>(),
  { modelValue: null, name: '', type: 'text', floatingLabel: true },
);

const emit = defineEmits<{
  'update:modelValue': [value: string | number];
  change: [];
  blur: [];
  click: [];
}>();

defineOptions({
  inheritAttrs: false,
});

const inputRef = useTemplateRef<HTMLInputElement>('inputRef');
const inputValue = useVModel(props, 'modelValue', emit);

const dataTest = computed(() => props.name || 'input-field');

const { tabKeyup } = useTabFocusEvent(inputRef);

onMounted(() => {
  if (!props.autofocus) return;

  inputRef.value?.focus();
});

const onBlur = () => {
  if (props.trim && inputValue.value && typeof inputValue.value === 'string') {
    inputValue.value = inputValue.value.trim();
  }

  emit('blur');
};

const onClick = () => {
  emit('click');
};
</script>

<template>
  <label
    :data-test="dataTest"
    class="flex h-input items-center rounded-t border-b bg-white py-0.5 text-base leading-5 transition-colors"
    :class="{
      'border-red-6 text-charcoal-6': hasErrors,
      'focus-within:border-primary-6 focus-within:text-primary-8': !hasErrors,
      'text-charcoal-6 hover:border-primary-6': !hasErrors && !disabled,
      'border-primary-6': !hasErrors && !disabled && !!inputValue,
      'ring ring-ocean-3': tabKeyup,
      'cursor-pointer': readonly && !disabled,
      'cursor-text': !readonly && !disabled,
      'cursor-not-allowed border-charcoal-4 text-charcoal-4': disabled,
    }"
  >
    <div class="relative flex h-full w-full flex-1 flex-col-reverse">
      <input
        v-title
        :id="name"
        v-bind="$attrs"
        ref="inputRef"
        v-model="inputValue"
        :placeholder="placeholder || label"
        :readonly="readonly"
        :disabled="disabled"
        class="input-field hide-selection h-full w-full truncate outline-none"
        :class="{
          'cursor-pointer': readonly && !disabled,
          'cursor-text': !readonly && !disabled,
          'text-charcoal-9': !disabled,
          'cursor-not-allowed text-charcoal-4': disabled,
          'placeholder:text-charcoal-6 focus:placeholder-transparent disabled:placeholder:text-charcoal-4':
            !floatingLabel,
          'pb-1 placeholder-transparent': floatingLabel,
          'px-2': !floatingLabel,
          capitalize: capitalized,
        }"
        :name="name"
        :type="type"
        :data-test="`${dataTest}-input`"
        :autofocus="autofocus"
        :autocomplete="autocomplete"
        @change="emit('change')"
        @blur="onBlur"
        @click="onClick"
      />
      <span
        v-if="floatingLabel"
        class="input-label block origin-bottom-left normal-case"
        :for="name"
      >
        {{ label }}
      </span>
      <p
        v-if="optional && !inputValue"
        class="absolute right-0 pb-1 text-charcoal-2"
      >
        Optional
      </p>
    </div>
    <div v-if="$slots.append" class="mx-3 flex-shrink-0">
      <slot name="append" />
    </div>
  </label>
</template>
